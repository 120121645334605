<script>
import { mapGetters } from "vuex";

export default {
	props: {
		active: {
			required: true,
			type: String,
		},
		goBack: {
			required: false,
			type: Boolean,
		},
		isTour: {
			required: false,
			type: Boolean,
		},
	},
	data() {
		return {
			items: [
				{
					name: "Reconocer",
					icon: "fa-solid fa-magnifying-glass",
				},
				{
					name: "Notificaciones",
					icon: "fa-solid fa-bell",
				},
				{
					name: "Usuarios",
					icon: "fa-solid fa-users",
				},
				{
					name: "Usuario",
					icon: "fa-solid fa-chart-column",
				},
				{
					name: "Metricas",
					icon: "fa-solid fa-gear",
				},
				{
					name: "Preguntas",
					icon: "fa-solid fa-question",
				},
			],
		};
	},
	computed: {
		...mapGetters({
			isAdmin: "auth/readIsAdmin",
			isLeader: "auth/readIsLeader",
			readNotifications: "getNotifications/readNotifications",
			//ERRORS
			readNotificationsError: "getNotifications/readError",
			readHasReadError: "hasReadNotification/readError",
		}),
		notificationsPending() {
			return this.readNotifications.filter((n) => {
				return n.is_unread;
			});
		},
	},
	methods: {
		getImage(image) {
			return `${require(`@/assets/images/components/NAVBARVOTACION/${image}`)}`;
		},
		emitGoBack() {
			if (!this.isTour) {
				this.$emit("goBack", true);
			}
		},
	},
};
</script>

<template>
	<div class="relative mt-auto">
		<div class="nav-bar flex flex-none justify-center items-center">
			<button
				id="goBack"
				class="flex-none block icon-link rounded-full btn-back green-light"
				v-if="goBack"
				@click="emitGoBack"
			>
				<font-awesome-icon
					icon="fa-solid fa-angle-left"
					class="green-light block w-full h-full"
				/>
			</button>
			<router-link
				:id="item.name"
				v-for="item in items"
				:key="item.name"
				class="flex-none block relative icon-link rounded-full"
				:class="[
					active == item.name ? 'bg-orange' : 'bg-gray',
					{ disabled: isTour },
				]"
				:to="{
					name: item.name,
				}"
				v-show="
					(item.name == 'Metricas' && isAdmin) ||
					(item.name == 'Usuarios' && isLeader) ||
					(item.name != 'Metricas' && item.name != 'Usuarios')
				"
				><img
					v-if="item.image && active != item.name"
					class="icon w-full h-full"
					:src="getImage(item.image)"
				/>
				<img
					v-if="item.image && active == item.name"
					class="icon w-full h-full"
					:src="getImage(item.imageActive)"
				/>
				<font-awesome-icon
					v-else-if="item.icon"
					:icon="item.icon"
					class="icon block w-full h-full"
					:class="active == item.name ? 'text-white' : 'green-light'"
				/>
				<span
					v-if="
						item.name == 'Notificaciones' &&
						notificationsPending.length > 0
					"
					class="flex b justify-center items-center badge text-xs"
				>
					{{
						notificationsPending.length > 9
							? ""
							: notificationsPending.length
					}}
				</span>
			</router-link>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.disabled {
	pointer-events: none;
}
.nav-bar {
	background-image: linear-gradient(
		to top,
		#cbcbcb 0%,
		#f5f5f5 35%,
		#f5f5f5 100%
	);
	height: 50px;
}

.icon-link {
	border: 3px solid #d6e2f0;
	width: 40px;
	height: 40px;
	padding: 7px;
	box-shadow: 0px 7px 14px rgba($color: #7ca9bd, $alpha: 0.75);

	&:not(:first-child) {
		margin-left: 10px;
	}
}

.btn-back {
	.icon {
		display: block;
		animation: bounce 0.9s infinite alternate;
	}
}

.badge {
	position: absolute;
	right: -7.5px;
	top: -7.5px;
	display: flex;
	min-width: 15px;
	height: 15px;
	background: $red;
	border-radius: 50%;
	color: #fff;
}
</style>
