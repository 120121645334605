<script>
import { title } from 'process'

export default {
    props: {
        selected: {
            type: Number,
            default() {
                return 0
            }
        },
		reactions: {
			type: Array,
            default() {
                return []
            }
		}
    },
	data() {
		return {
			elementCurrent: null,
			active: false,
			interval: null,
		}
	},
	computed: {
		inButton() {
			if(!this.selected) {
				return this.reactions[0]
			}
			return this.reactions.find(r => r.id == this.selected)
		}
	},
    methods: {
        getReactionImage(image) {
			return `${require(`@/assets/images/components/reactions/${image}`)}`;
		},
		move(event) {
			let $parent = this.$el
			let parent_coors = $parent.getBoundingClientRect()
			let parent_h = $parent.offsetHeight
			console.log(parent_coors.y + parent_h)
			console.log(event.changedTouches[0].clientY)
			if(parent_coors.y + parent_h + 40 <  event.changedTouches[0].clientY) {
				this.active = false
				this.elementCurrent = null
				return
			} else {
				this.active = true
			}
			

			if(!this.active) {
				return
			}
			if(this.selected) {
				return
			}
			var touch = event.changedTouches[0];
			

			let $elementCurrent = this.elementCurrent || this.$refs.reactionbar.firstChild
			this.elementCurrent = $elementCurrent
			this.elementCurrent.classList.add('hover')
			let coors = $elementCurrent.getBoundingClientRect()
			let offsetX = coors.x
			let w = $elementCurrent.offsetWidth

			let children = this.$refs.reactionbar.children
			let indexCurrent = Array.from(this.$refs.reactionbar.children).indexOf(this.elementCurrent)
			let isFirst = indexCurrent == 0
			let isLast = indexCurrent == children.length - 1
			
			if (offsetX >= event.changedTouches[0].clientX && !isFirst) {
				if($elementCurrent.previousSibling) {
					this.elementCurrent.classList.remove('hover')
					this.elementCurrent = $elementCurrent.previousSibling
				}
			} else if((offsetX + w) <= event.changedTouches[0].clientX && !isLast) {
				if($elementCurrent.nextSibling) {
					this.elementCurrent.classList.remove('hover')
					this.elementCurrent = $elementCurrent.nextSibling
				}
			} else {
				console.log('current')

				this.elementCurrent = $elementCurrent
			}

			console.log(Array.from(this.$refs.reactionbar.children).indexOf(this.elementCurrent))
			if(this.elementCurrent) {
				this.elementCurrent.classList.add('hover')
			}

			
		},
		start() {
			if(this.interval) {
				clearTimeout(this.interval)
				this.interval = null
			}
			this.$refs.reactionbar.children.forEach(element => {
				element.classList.remove('hover')
			});
			if(this.selected) {
				return
			}
			let vm = this
			console.log('start')
			this.interval = setTimeout(function () {
				vm.active = true
				let $elementCurrent = vm.$refs.reactionbar.firstChild
				vm.elementCurrent = $elementCurrent
			}, 500)

		},
		end() {
			if(this.selected) {
				return
			}
			console.log('end')
			if(this.interval) {
				clearTimeout(this.interval)
				this.interval = null
			}

			this.active = false
			if(this.elementCurrent) {
				this.elementCurrent.click()
				this.$refs.reactionbar.children.forEach(element => {
					element.classList.remove('hover')
				});
			}
		},

		mouseUp(event) {
			if(event.pointerType == 'touch') {
				return
			}
			if(this.interval) {
				return
			}
			this.$refs.reactionbar.children.forEach(element => {
				element.classList.remove('hover')
			});
			if(this.selected) {
				return
			}
			let vm = this
			console.log('start')
			vm.active = true
			let $elementCurrent = vm.$refs.reactionbar.firstChild
			vm.elementCurrent = $elementCurrent
		},
		triggerReaction(reaction) {
			if(this.selected) {
				return
			}
			console.log(reaction)
			this.active = false
			this.$emit('change', reaction)
		}
    }
}
</script>
<template>
    <div class="w-full reaction" @touchmove.passive="move" >
		<div ref="reactionbar"  @click.stop="" class="flex reaction-bar" :class="{'reaction-bar--active': active}">
			<button class="text-sm reaction-trigger" v-for="reaction in reactions" :key="`key-${reaction.id}`" @click="triggerReaction(reaction)">
				<img class="w-6 block mx-auto" :src="getReactionImage(reaction.image)" alt="">
				<span class="text-sm font-bold reaction-title">{{reaction.title}}</span>  
			</button>
		</div>
        <button class="reaction-btn flex items-center" :class="{'grayscale': !selected }"  @touchstart="start" @touchend="end" @click.stop="mouseUp">
            <img class="w-6 block mr-2" :src="getReactionImage(inButton.image)" alt="">  
            <span class="text-sm block mt-auto font-bold">
                {{inButton.title}}
            </span>
        </button>
    </div>
</template>
<style lang="scss" scoped>
img{
	user-select: none;
	pointer-events: none;
}
.reaction{
	position: relative;
	margin-top: 0.2rem;
	user-select: none;

	&-bar{
		position: absolute;
		background: #fff;
		border-radius: 60px;
		z-index: 2;
		padding: 0.5rem;
		bottom: 0;
		opacity:0;
		pointer-events: none;
		transition: 0.3s;
		width: 100%;
		left: 0;
		pointer-events: none;
		&--active{
			pointer-events: all;
			opacity: 1;
			pointer-events: all;
			bottom: 100%;
		}
		
	}
	&-trigger{
		width: 100%;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: 0.3s;
		img{
			user-select: none;
			pointer-events: none;
		}
		.reaction{
			&-title{
				opacity: 0;
				bottom: 0;
				transition: 0.3s;
				pointer-events: none;
				font-size: 0.5rem;
				line-height: 0.7rem;
				padding: 0 0.2rem;
			}
		}
		&.hover{
			transform: scale(1.5) translateY(-20%);
			outline: none;
			.reaction{
				&-title{

					opacity: 1;
					bottom: 100%;
				}
			}
		}
		@media only screen and (hover: hover) {

			&:hover{
				transform: scale(1.5) translateY(-20%);
				outline: none;
				.reaction{
					&-title{

						opacity: 1;
						bottom: 100%;
					}
				}
			}

		}
	}
	&-title{
		position: absolute;
		background: rgba($color: #000000, $alpha: 0.9);
		color: #fff;
		font-size: 0.7rem;
		padding: 0 .5rem;
		border-radius: 1rem;
		bottom: 100%;
		left: 50%;
		transform: translateX(-50%);
		white-space: nowrap;
	}
}
.overlay {
	height: calc(100% - 1rem);
}
.image {
	height: get-vh(860px);
	margin-bottom: -2rem;
}

.grayscale{
	filter: grayscale(100%);
}

.font-bold{
	font-weight: bold;
}

.message {
	border: get-vh(8px) solid #d6e2f0;
}

.item {
	box-shadow: 0px 8px 6px rgba($color: #224c57, $alpha: 0.25);

	&--unread {
		box-shadow: none;
		color: #fff;
		border: none;

		.icon_user {
			color: $orange;
			background: $gray-light;
		}
	}
}

.icon_user {
	box-sizing: content-box;
	border: get-vh(6px) solid #d6e2f0;
	width: get-vh(120px);
	height: get-vh(120px);
	box-shadow: 0px 0px 5px 2px rgba($color: #d6e2f0, $alpha: 0.8);
}

@keyframes go-in {
	0% {
		transform: translateX(100vw);
	}
	100% {
		transform: translateX(0vw);
	}
}

@keyframes go-out {
	0% {
		transform: translateX(0vw);
	}
	100% {
		transform: translateX(-100vw);
	}
}
.modal-base {
	opacity: 0;
	transform: translateX(100vw);
	background: none;
}
.modal-active {
	opacity: 1;
	animation: go-in 0.4s;
	transform: translateX(0vw);
}
.modal-out {
	opacity: 1;
	animation: go-out 0.4s;
	transform: translateX(-100vw);
}

.modal-wrap {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-color: rgba($color: #4a6c85, $alpha: 0.5);
	z-index: 10000000;
}
</style>
