var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative mt-auto"},[_c('div',{staticClass:"nav-bar flex flex-none justify-center items-center"},[(_vm.goBack)?_c('button',{staticClass:"flex-none block icon-link rounded-full btn-back green-light",attrs:{"id":"goBack"},on:{"click":_vm.emitGoBack}},[_c('font-awesome-icon',{staticClass:"green-light block w-full h-full",attrs:{"icon":"fa-solid fa-angle-left"}})],1):_vm._e(),_vm._l((_vm.items),function(item){return _c('router-link',{directives:[{name:"show",rawName:"v-show",value:(
				(item.name == 'Metricas' && _vm.isAdmin) ||
				(item.name == 'Usuarios' && _vm.isLeader) ||
				(item.name != 'Metricas' && item.name != 'Usuarios')
			),expression:"\n\t\t\t\t(item.name == 'Metricas' && isAdmin) ||\n\t\t\t\t(item.name == 'Usuarios' && isLeader) ||\n\t\t\t\t(item.name != 'Metricas' && item.name != 'Usuarios')\n\t\t\t"}],key:item.name,staticClass:"flex-none block relative icon-link rounded-full",class:[
				_vm.active == item.name ? 'bg-orange' : 'bg-gray',
				{ disabled: _vm.isTour } ],attrs:{"id":item.name,"to":{
				name: item.name,
			}}},[(item.image && _vm.active != item.name)?_c('img',{staticClass:"icon w-full h-full",attrs:{"src":_vm.getImage(item.image)}}):_vm._e(),(item.image && _vm.active == item.name)?_c('img',{staticClass:"icon w-full h-full",attrs:{"src":_vm.getImage(item.imageActive)}}):(item.icon)?_c('font-awesome-icon',{staticClass:"icon block w-full h-full",class:_vm.active == item.name ? 'text-white' : 'green-light',attrs:{"icon":item.icon}}):_vm._e(),(
					item.name == 'Notificaciones' &&
					_vm.notificationsPending.length > 0
				)?_c('span',{staticClass:"flex b justify-center items-center badge text-xs"},[_vm._v(" "+_vm._s(_vm.notificationsPending.length > 9 ? "" : _vm.notificationsPending.length)+" ")]):_vm._e()],1)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }