<script>
import { mapActions, mapMutations, mapGetters } from "vuex";

import Footer from "@/layouts/Footer.vue";
import NavBarVotacion from "@/components/NavBarVotacion.vue";
import Modal from "@/components/Modal.vue";
import ReactionButton from "@/components/ReactionButton.vue";
import {reactNotification} from '../../api/notifications/get'

export default {
	props: {
		isTour: {
			required: false,
			type: Boolean,
		},
	},
	components: {
		Footer,
		NavBarVotacion,
		Modal,
		ReactionButton,
	},
	data() {
		return {
			messages: [],
			modal: {
				title: null,
				agency: null,
				work_position: null,
				message: null,
				comment: null,
				show: false,
				showComment: false,
				gone: false,
				type: '',
				reaction: null,
			},
			selectedType: 'vote',
			featching: null
		};
	},
	async created() {
		await this.getReactions();
		await this.loadMessages();
		let vm = this
		this.featching = setInterval(() => {
			vm.loadMessages(false)
		}, 5000)
	},

	beforeDestroy() {
		clearInterval(this.featching)
	},
	computed: {
		votes() {
			return this.messages.filter(m => m.type == 'vote' && m.is_unread).length
		},
		reactions() {
			return this.messages.filter(m => m.type == 'reaction' && m.is_unread).length
		},
		showMessage() {
			return this.messages.filter(m => m.type == this.selectedType)
		},
		...mapGetters({
			isLoading: "loading/isLoading",
			//EMITTERS
			readEmitter: "getUsers/readUsers",
			//NOTIFICATIONS
			readNotifications: "getNotifications/readNotifications",
			//ERRORS
			readNotificationsError: "getNotifications/readError",
			readReactions: "getReactions/readReactions",
			readHasReadError: "hasReadNotification/readError",
			readToken: 'loginStore/readToken',
		}),
	},
	methods: {
		...mapMutations({
			setLoading: "loading/SET_LOADING",
			//EMITTERS
			getEmitterId: "getUsers/SET_ID",
			setEmitterPagination: "getUsers/SET_PAGINATION",
			//NOTIFICATIONS
			setHasReadId: "hasReadNotification/SET_ID",
			setNotificationsId: "getNotifications/SET_ID",

			//ERRORS
			setNotificationsError: "getNotifications/SET_ERROR",
			setHasReadError: "hasReadNotification/SET_ERROR",
		}),
		...mapActions({
			//EMITTERS
			getEmitter: "getUsers/getUsers",
			//NOTIFICATIONS
			setHasRead: "hasReadNotification/hasRead",
			getNotifications: "getNotifications/getNotifications",
			getReactions: 'getReactions/getReactions'
		}),
		showModal() {
			this.modal.gone = false;
			this.modal.show = true;
		},
		hideModal() {
			this.modal.gone = true;
			this.modal.show = false;
		},
		showComment() {
			if (this.modal.comment) this.modal.showComment = true;
		},
		hideComment() {
			this.modal.showComment = false;
		},
		getImage(image) {
			return `${require(`@/assets/images/views${this.$route.path.toUpperCase()}/${image}`)}`;
		},
		selectMessage(message) {
			this.modal.title = message?.title;
			this.modal.work_position = message?.work_position;
			this.modal.agency = message?.agency;
			this.modal.message = message?.content;
			this.modal.comment = message?.comment;
			this.modal.type = message?.type;
			this.modal.reaction = this.readReactions.find(r => r.id == message.reaction_id);
			this.showModal();

			if (!this.isTour) {
				if (message?.is_unread) this.markRead(message?.id);
			}
		},
		markRead(id) {
			this.cleanErrors();
			this.setHasReadId(id);
			this.setHasRead().finally(() => {
				this.loadMessages();
			});
		},
		loadMessages(loading = true) {
			this.setLoading(loading);
			this.cleanErrors();
			this.setNotificationsId(null);
			this.getNotifications()
				.then((result) => {
					if (result) {
						// this.loadEmitters(this.readNotifications);
						this.$set(this, 'messages', this.readNotifications)
					} else {
						this.messages = [];
					}
				})
				.finally(() => {
					this.setLoading(false);
				});
		},
		loadEmitters(payload) {
			payload.filter((m, key) => {
				this.$set(this.messages, key, m);
				this.setEmitterPagination(null);
				this.getEmitterId(m.emitter_id);
				this.getEmitter().then((response) => {
					if (response) {
						this.$set(
							this.messages[key],
							"photo",
							this.readEmitter[0].photo || null
						);
						this.$set(
							this.messages[key],
							"work_position",
							this.readEmitter[0].work_position || null
						);
						this.$set(
							this.messages[key],
							"agency",
							this.readEmitter[0].agency || null
						);
					}
				});
			});
		},
		cleanErrors() {
			this.setHasReadError(null);
			this.setNotificationsError(null);
		},
		getReactionImage(image) {
			return `${require(`@/assets/images/components/reactions/${image}`)}`;
		},

		async emitReaction(reaction, notification) {
			notification.reaction_id = reaction.id

			try {
				let result = await reactNotification(this.readToken, {parent_id: notification.id, reaction_id: reaction.id})
			} catch (error) {
			}
		}

		
	},
};
</script>
<template>
	<div class="relative h-full flex flex-col justify-center items-center">
		<div class="modal-wrap" v-if="modal.show || modal.showComment">
			<Modal
				buttonText="Continuar"
				@click.native="hideModal(), showComment()"
				class="modal-base"
				:class="{ 'modal-active': modal.show, 'modal-out': modal.gone }"
			>
				<div class="text-white text-center">
					<span class="b yellow block text-xl">{{
						modal.title
					}}</span>
					<span
						class="block text-xl"
						v-capitalizeWords="modal.work_position"
					></span>
					<span
						class="block text-xl"
						v-capitalizeWords="modal.agency"
					></span>
					<hr class="bg-yellow h-0.5 my-2 w-full block" />
				
					<span class="block text-lg py-1">{{ modal.message }}</span>
					<div v-if="modal.type == 'reaction'" class="flex">
						<img :src="getReactionImage(modal.reaction.image)" alt="" class="mx-auto w-20 animtae-smile">
					</div>
				</div>
			</Modal>
			<Modal
				buttonText="Continuar"
				class="modal-base"
				:class="{ 'modal-active': modal.showComment }"
				@click.native="hideComment()"
			>
				<div class="text-white text-center">
					<span class="b yellow block text-xl"
						>Comentarios adicionales:</span
					>
					<span class="block text-lg py-1">{{ modal.comment }}</span>
				</div>
			</Modal>
		</div>
		<div class="my-2 flex flex-col overlay bg-white mx-auto">
			<div class="flex mb-6">
				<button class="w-full text-center font-bold tab " :class="{'tab--active': selectedType == 'vote'}" @click="selectedType = 'vote'">
					Reconocimientos
					<span class="tab-indicator text-white" v-if="votes">{{votes}}</span>
				</button>
				<button class="w-full text-center font-bold tab" :class="{'tab--active': selectedType == 'reaction'}"  @click="selectedType = 'reaction'">
					Reacciones
					<span class="tab-indicator text-white" v-if="reactions">{{reactions}}</span>
				</button>
			</div>
			<div
				class="bg-contain bg-top bg-no-repeat mx-5 pt-0 mt-1"
				:style="{
					'background-image': `url(${getImage(
						'notificaciones_bg.png'
					)})`,
				}"
			>
				
				<p class="b bg-overlay text-center text-xl leading-5 mb-3">
					<span class="green block">¡Felicidades!</span>
				</p>
				<p class="bg-overlay text-lg text-center" v-if="selectedType == 'vote'">
					Tus compañeros te reconocen como un
					<span class="red b">Creador</span> de
					<span class="blue b">Momentos </span>
					<span class="green b">Felices</span>.
					<span class="block">
						Haz <span class="b red">clic</span> para revisar tus
						insignias.
					</span>
				</p>
				<p class="bg-overlay text-lg text-center" v-else-if="selectedType == 'reaction'">
					Tus compañeros han reaccionado a los reconocimientos que han recibido. 
					<span class="block">
						Haz <span class="b red">clic</span> para revisar sus
						reacciones.
					</span>
				</p>
			</div>
			<div
				v-if="!isLoading && showMessage.length > 0"
				class="messages-list h-4/6 overflow-y-scroll pt-3"
			>
				<div
					@click="selectMessage(m)"
					v-for="(m, key) in showMessage"
					:id="`notification_${key}`"
					:key="m.id"
					class="message mb-3 flex justify-between items-center p-2 rounded-xl mx-5 overflow-visible cursor-pointer"
					:class="
						m.is_unread
							? 'item item--unread cursor-pointer gradient-orange'
							: 'item'
					"
				>
					<span
						class="flex flex-none justify-center items-center icon_user rounded-full overflow-hidden"
					>
						<font-awesome-icon
							v-if="!m.emitter.photo"
							icon="fa-solid fa-user  text-lg"
						/>
						<img
							v-else
							class="block w-full h-full object-cover object-center flex-none transform rotate-90"
							:src="m.emitter.photo"
							alt="Trofeo"
						/>
					</span>
					<div class="label w-5/6 pl-2 flex-grow">
						<div class="pb-2">

						<span class="block b text-md truncate">{{
							m.title
						}}</span>
						<span class="block text-md truncate">{{
							m.content
						}}</span>
						</div>
						<ReactionButton :selected="m.reaction_id || 0" :reactions="readReactions" @change="(payload) => emitReaction(payload, m)"/>
					</div>
				</div>
			</div>
			<div v-else class="text-md m-auto font-md b blue px-5 text-center">
				Aún no cuentas con reconocimientos
			</div>

			<Nav-bar-votacion :active="this.$route.name"></Nav-bar-votacion>
			<Footer class="static"></Footer>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.animtae-smile{
	animation: smileIn .4s forwards ease-in-out;
	animation-delay: 0.4s;
	opacity: 0;
}

@keyframes smileIn{
  0%{
    opacity: 0;
    transform: scale(0.3) translate3d(0,0,0);
  }
  50%{
    opacity: 0.9;
    transform: scale(1.1);
  }
  80%{
    opacity: 1;
    transform: scale(0.89);
  }
  100%{
    opacity: 1;
    transform: scale(1) translate3d(0,0,0);
  }
}
.tab{
	padding: 1rem 0.5rem;
	padding-right: 24px;
	display: flex;
	justify-content: center;
	font-size: 0.9rem;
	position: relative;
	&--active{
		color: #17ad12;
		box-shadow: inset 0 -4px #17ad12;
	}
	&-indicator{
		position: absolute;
		right: 0.5rem;
		margin-left: auto;
		background-color: $red-dark;
		color: #fff;
		width: 20px;
		height: 20px;
		line-height: 20px;
		font-size: 0.7rem;
		font-weight: 700;
		display: inline-block;
		border-radius: 20px;
	}
}
.overlay {
	height: calc(100% - 1rem);
}
.image {
	height: get-vh(860px);
	margin-bottom: -2rem;
}

.grayscale{
	filter: grayscale(100%);
}

.font-bold{
	font-weight: bold;
}

.message {
	border: get-vh(8px) solid #d6e2f0;
	user-select: none;
}

.item {
	box-shadow: 0px 8px 6px rgba($color: #224c57, $alpha: 0.25);

	&--unread {
		box-shadow: none;
		color: #fff;
		border: none;

		.icon_user {
			color: $orange;
			background: $gray-light;
		}
	}
}

.icon_user {
	box-sizing: content-box;
	border: get-vh(6px) solid #d6e2f0;
	width: get-vh(120px);
	height: get-vh(120px);
	box-shadow: 0px 0px 5px 2px rgba($color: #d6e2f0, $alpha: 0.8);
}

@keyframes go-in {
	0% {
		transform: translateX(100vw);
	}
	100% {
		transform: translateX(0vw);
	}
}

@keyframes go-out {
	0% {
		transform: translateX(0vw);
	}
	100% {
		transform: translateX(-100vw);
	}
}
.modal-base {
	opacity: 0;
	transform: translateX(100vw);
	background: none;
}
.modal-active {
	opacity: 1;
	animation: go-in 0.4s;
	transform: translateX(0vw);
}
.modal-out {
	opacity: 1;
	animation: go-out 0.4s;
	transform: translateX(-100vw);
}

.modal-wrap {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-color: rgba($color: #4a6c85, $alpha: 0.5);
	z-index: 10000000;
}
</style>
